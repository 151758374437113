<template>
  <div class="page4">
    <Row class="content">
      <Col span="8">
        <div class="list">
          <div class="left">
            <span class="title"><span class="title-4">合作景区入园量</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <div style="width: 100%">
              <Row type="flex" align="middle" justify="center">
                <Col span="24">
                  <dv-capsule-chart :config="config" style="width: 100%; height: 100%; padding: 1.8rem 1.5rem 0 1.5rem" />
                </Col>
              </Row>

              <!--                            <area-chart ref="chart1" id="left_1" :select-range-date="selectRangeDate" :config="cnfigData1"></area-chart>-->
            </div>
            <!--                        <div class="chart-32">
                                        <radar-chart ref="chart2" id="left_2" :data="chatRadarData"></radar-chart>
                                    </div>-->
          </div>
        </div>
        <div class="list">
          <div class="left">
            <span class="title"><span class="title-10">会员总数</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <Row :gutter="10" type="flex" align="middle" justify="center" style="margin-top: 10%">
              <Col span="12">
                <Row :gutter="10" type="flex" align="middle" justify="center">
                  <Col span="11">
                    <div style="color: #3de7c9; font-size: 18px">会员总数：</div>
                  </Col>
                  <Col span="12">
                    <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="endVal" :duration="duration" />
                    <!-- <dv-digital-flop :config="config" style="width: 100px; height: 50px" /> -->
                  </Col>
                </Row>
              </Col>
              <Col span="12">
                <Row :gutter="10" type="flex" align="middle" justify="center">
                  <Col span="11">
                    <div style="color: #3de7c9; font-size: 18px">有效会员数量：</div>
                  </Col>
                  <Col span="12">
                    <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="endVal2" :duration="duration" />
                    <!-- <dv-digital-flop :config="config" style="width: 100px; height: 50px" /> -->
                  </Col>
                </Row>
              </Col>
            </Row>
            <!-- <div class="chart-68">
              <bar-chart ref="chart3" id="left_3" :data="configData2"></bar-chart>
            </div>
            <div class="chart-32">
              <radar-chart ref="chart4" id="left_4" :data="officeRadarData"></radar-chart>
            </div> -->
          </div>
        </div>
      </Col>
      <Col span="8">
        <Echart :cdata="cdata" />
        <!-- <div class="circlePie" id="circlePie">
          <canvas id="main" width="500" height="500"></canvas>
          <canvas id="dot"></canvas>
        </div> -->
      </Col>
      <Col span="8">
        <div class="list2">
          <div class="right">
            <span class="title"><span class="title-10">历年新增续费会员走势图</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <share-line ref="shareLine" id="right_1" :data="wholesaleData"></share-line>
            <!--            <div class="chart-32">
              <radar-chart ref="chart5" id="right_1" :data="friendRadarData"></radar-chart>
            </div>
            <div class="chart-68">
              <dv-scroll-board :config="sourceConfig" style="width:100%;height:80%;padding: 1.2rem 0.8rem;" />
            </div>-->
          </div>
        </div>
        <!--        <div class="list">
          <div class="right">
            <span class='title'><span class="title-4">去向分析</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <div class="chart-32">
              <radar-chart ref="chart7" id="right_3" :data="momentsRadarData"></radar-chart>
            </div>
            <div class="chart-68">
              <dv-scroll-board :config="destinationConfig" style="width:100%;height:80%;padding: 1.2rem 0.8rem;" />
            </div>
          </div>
        </div>-->
      </Col>
    </Row>
    <Row class="bottom-list">
      <Col span="8">
        <div class="list">
          <div class="bottom">
            <span class="title"><span class="title-10">会员所在地域统计</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <dv-scroll-board :config="scrollConfig" style="width: 100%; height: 80%; padding: 1.2rem 0.8rem" />
            <!--                        <double-line ref="chart9" id="bottom_1"></double-line>-->
          </div>
        </div>
      </Col>
      <Col span="8" style="pointer-events: none">
        <!-- <div class="list">
          <div class="bottom">
            <span class="title"><span class="title-10">企业数据分析</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <double-bars ref="chart10" id="bottom_2" :data="enterpriseData"></double-bars>
          </div>
        </div> -->
      </Col>
      <Col span="8">
        <div class="list">
          <div class="bottom">
            <span class="title"><span class="title-10">会员年龄段占比</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <three-bar-chart ref="chart11" id="bottom_3" :data="barchart"></three-bar-chart>
          </div>
        </div>
      </Col>
      <!--            <Col span="8">
                      <div class="list right-bottom">
                          <div class="bottom bottom1">
                              <span class='title'><span class="title-10">各部门红包转账对比</span></span>
                              <span class="angle1"></span>
                              <span class="angle2"></span>
                              <span class="angle3"></span>
                              <span class="angle4"></span>
                              <pie-chart ref="chart12" id="bottom_4"></pie-chart>
                          </div>
                      </div>
                  </Col>-->
    </Row>
  </div>
</template>

<script>
/*const areaChart = ()=> import('./components/areaChart');*/
import { getSPInAndOutWeightTJ, getSPPFweightTJ, getSPretailBusiInWeiList, getSPretailInTJ, getSPretailInWeiList, getSPvarietyWeightTJ } from '@/api/home'
import CountTo from 'vue-count-to'
// const radarChart = () => import('./components/radar')
// const barChart = () => import('./components/page4/LineMarker')
/*const doubleBarChart = ()=> import('./components/page2/doubleBarChart');*/
/*const singleAreaChart = () => import('./components/page2/singleAreaChart');*/
/*const doubleLine  = () => import('./components/page2/doubleLine');*/
const threeBarChart = () => import('./components/page4/linkPie')
/*const pieChart = () => import('./components/page2/pieChart');*/
// const doubleBars = () => import('./components/page4/doubleBars')
const shareLine = () => import('./components/page4/shareLine')
const Echart = () => import('./components/page4/chart')
export default {
  name: 'page2',
  props: ['selectRangeDate'],
  components: {
    CountTo,
    /* areaChart,*/
    // radarChart,
    // barChart,
    shareLine,
    Echart,
    /* doubleBarChart,*/
    /* singleAreaChart,*/
    /*doubleLine,*/
    threeBarChart
    /*   pieChart,*/
    // doubleBars
  },
  data() {
    return {
      startVal: 0,
      endVal: 15628,
      endVal2: 12103,
      duration: 5000,
      cdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '建昌县',
          value: 2,
          upnum: 2,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          }
        },
        {
          name: '绥中县',
          value: 2,
          upnum: 2
        },
        {
          name: '兴城市',
          value: 2,
          upnum: 2
        },
        {
          name: '连山区',
          value: 2,
          upnum: 2
        },
        {
          name: '南票区',
          value: 7,
          upnum: 5
        },
        {
          name: '龙港区',
          value: 2,
          upnum: 2
        }
      ],
      wholesaleData: {
        dataset: {
          source: [
            ['product', '2022', '2023', '2024'],
            ['新增会员', 1210, 3258, 8087],
            ['续费会员', 611, 3632, 7388]
          ]
        }
        // pieParam: {
        //   color: 'rgb(137,189,27)'
        // }
      },
      enterpriseData: {
        data: [],
        data2: [],
        yAxis: []
      },
      barchart: {
        data: [
          {
            name: '0~16岁',
            value: '805'
          },
          {
            name: '16~30岁',
            value: '3652'
          },
          {
            name: '30~60岁',
            value: '4021'
          },
          {
            name: '60岁以上',
            value: '560'
          }
        ],
        legend: ['sfd', '23', '23543', '32423', '23423', '23432']
      },
      destinationConfig: {
        columnWidth: [80],
        align: ['center'],
        header: ['去往地', '进场重量(Kg)', '进场金额(元)'],
        data: [],
        indexHeader: '排行',
        index: true,
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent'
      },
      sourceConfig: {
        columnWidth: [80],
        align: ['center'],
        header: ['来源地', '进场重量(Kg)', '进场金额(元)'],
        data: [],
        indexHeader: '排行',
        index: true,
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent'
      },
      scrollConfig: {
        align: ['center'],
        header: ['地域', '人数'],
        data: [
          ['连山区', 22330],
          ['建昌县', 12035],
          ['绥中县', 8126],
          ['兴城市', 7521],
          ['南票区', 2050],
          ['龙港区', 1620],
          ['其它地区', 886]
        ],
        indexHeader: '排行',
        index: true,
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent'
      },
      config: {
        data: [
          {
            name: '关东葫芦古镇',
            value: 12021
          },
          {
            name: '兴城古城',
            value: 22312
          },
          {
            name: '兴城海滨',
            value: 15687
          },
          {
            name: '东戴河',
            value: 26238
          },
          {
            name: '欢乐龙湾',
            value: 26651
          }
        ]
      },
      everyPer: 0,
      xOffset: 0,
      circle: {
        x: 250,
        y: 250,
        radius: 218
      },
      title: ['今日本地屠宰供应量:456,789', '本地菜交易量:134,23', '外埠肉供应量:123,12', '外地菜交易量:234,234'],
      /* cnfigData1: {
        color: '#5CB1C1',
        name: ['（次）', '（人）'],
        data: [
          {
            name: '聊天次数',
            color: ['#9e70ff', '#6e5eff'],
            data: [200, 12, 21, 54, 260, 130, 210],
          },
          {
            name: '聊天人数',
            color: ['#48cefd', '#5356f1'],
            data: [50, 182, 234, 191, 190, 30, 10],
          }
        ]
      },*/
      configData2: [],
      /*  chatRadarData: {
        title: '各部门聊天对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [
          {text: '一部'},
          {text: '二部'},
          {text: '三部'},
          {text: '四部'},
          {text: '五部'},
          {text: '六部'}
        ],
        data: [
          {
            name: '聊天次数',
            color: '#0DF5F8',
            value: [100, 8, 0.40, -80, 2000, 332],
          },
          {
            name: '聊天人数',
            color: '#7921AD',
            value: [60, 5, 0.30, -100, 1500, 221],
          }
        ]
      },*/
      officeRadarData: {
        title: '农贸进场重量分析',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [],
        data: [
          {
            name: '重量',
            color: '#55D35B',
            value: [20, 50, 600, 1000]
          },
          {
            name: '重量',
            color: '#55D35B',
            value: [20, 50, 600, 1000]
          },
          {
            name: '重量',
            color: '#55D35B',
            value: [20, 50, 600, 1000]
          }
        ]
      },
      friendRadarData: {
        title: '来原产地进场重量对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [{ text: '黑龙江' }, { text: '河北' }, { text: '河南' }, { text: '山东' }, { text: '江苏' }, { text: '陕西' }],
        data: [
          {
            name: '进场重量',
            color: '#FA8486',
            value: [0, 0, 0, 0, 2000, 332]
          }
        ]
      },
      momentsRadarData: {
        title: '去往产地重量对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [{ text: '黑龙江' }, { text: '河北' }, { text: '河南' }, { text: '山东' }, { text: '江苏' }, { text: '陕西' }],
        data: [
          {
            name: '进场重量',
            color: '#D91748',
            value: [0, 0, 0.0, 0, 2000, 332]
          }
        ]
      },
      warea: { x: 250, y: 250, max: 700 },
      dots: [],
      resizeFn: null,
      animationFrame1: null,
      animationFrame2: null,
      centerBox: {
        width: 0,
        height: 0
      }
    }
  },
  methods: {
    getSPvarietyWeightTJ() {
      getSPvarietyWeightTJ().then((res) => {
        this.config.data = res.data
          .map((item) => {
            return Object.assign({}, { name: item.name, value: item.weight })
          })
          .splice(0, 6)
        this.config = { ...this.config }
      })
    },
    getSPPFweightTJ() {
      getSPPFweightTJ().then((res) => {
        console.log(res)
        const product = [
          'product',
          ...res.data
            .map((item) => {
              return item.date
            })
            .reverse()
        ]
        this.wholesaleData.pieParam = product[1]
        const inweight = [
          '进场',
          ...res.data.map((item) => {
            return item.inweight
          })
        ]
        const outweight = [
          '出场',
          ...res.data.map((item) => {
            return item.inweight
          })
        ]
        this.wholesaleData.dataset.source = [product, inweight, outweight]
      })
    },
    getSPretailInTJ() {
      getSPretailInTJ().then((res) => {
        this.configData2 = res.data.reverse()
      })
    },
    getSPretailInWeiList() {
      getSPretailInWeiList().then((res) => {
        this.officeRadarData.indicator = res.data.map((item) => {
          return Object.assign({}, { text: item.nodename })
        })
        this.officeRadarData.data[0].value = res.data.map((item) => item.weight)
        const centerCirc = res.data.slice(0, 4)
        console.log(centerCirc)
        this.title = centerCirc.map((item) => {
          return `${item.nodename}:${item.weight}`
        })
      })
    },
    getSPretailBusiInWeiList() {
      getSPretailBusiInWeiList().then((res) => {
        console.log(res)
      })
    },
    getSPInAndOutWeightTJ() {
      getSPInAndOutWeightTJ({ queryType: 'area' }).then((res) => {
        this.scrollConfig.data = res.data.map((item) => {
          return Object.assign([], [item.areaname, item.weight])
        })
        this.scrollConfig = { ...this.scrollConfig }
      })
    },
    getLinkData() {
      getSPInAndOutWeightTJ({ queryType: 'class' }).then((res) => {
        this.barchart.data = res.data.map((item) => {
          return Object.assign({}, { value: item.weight, name: item.classtype })
        })
        this.barchart.legend = res.data.map((item) => {
          return item.classtype
        })
      })
    },
    getEnterpriseData() {
      getSPInAndOutWeightTJ({ queryType: 'node' }).then((res) => {
        this.enterpriseData.data2 = res.data.map((item) => {
          return item.weight
        })
        this.enterpriseData.yAxis = res.data.map((item) => {
          return item.nodename
        })
      })
    },
    // drawDot() {
    //   let canvas = document.getElementById('dot')
    //   canvas.width = document.querySelector('#circlePie').offsetWidth
    //   canvas.height = document.querySelector('#circlePie').offsetHeight
    //   let ctx = canvas.getContext('2d')
    //   ctx.clearRect(0, 0, canvas.width, canvas.height)
    //   // 将鼠标坐标添加进去，产生一个用于比对距离的点数组
    //   let ndots = [this.warea].concat(this.dots)
    //   this.dots.forEach((dot) => {
    //     // 粒子位移
    //     dot.x += dot.xa
    //     dot.y += dot.ya
    //     // 遇到边界将加速度反向
    //     dot.xa *= dot.x > canvas.width || dot.x < 0 ? -1 : 1
    //     dot.ya *= dot.y > canvas.height || dot.y < 0 ? -1 : 1
    //     // 绘制点
    //     ctx.fillStyle = '#ffffff'
    //     ctx.beginPath()
    //     ctx.arc(dot.x - 0.5, dot.y - 0.5, 2, 0, 2 * Math.PI, true)
    //     ctx.closePath()
    //     ctx.fill()
    //     // 循环比对粒子间的距离
    //     for (let i = 0; i < ndots.length; i++) {
    //       let d2 = ndots[i]
    //       if (dot === d2 || d2.x === null || d2.y === null) continue
    //       let xc = dot.x - d2.x
    //       let yc = dot.y - d2.y
    //       // 两个粒子之间的距离
    //       let dis = Math.sqrt(xc * xc + yc * yc)
    //       // 距离比
    //       let ratio
    //       // 如果两个粒子之间的距离小于粒子对象的max值，则在两个粒子间画线
    //       if (dis < d2.max) {
    //         // 计算距离比
    //         ratio = (d2.max - dis) / d2.max
    //         // 画线
    //         ctx.beginPath()
    //         ctx.lineWidth = ratio / 2
    //         if (d2 === this.warea) {
    //           ctx.strokeStyle = 'rgba(255,255,255,0)'
    //         } else {
    //           // 距离变大 连线颜色变浅
    //           ctx.strokeStyle = 'rgba(255,255,255,' + (ratio + 0.2) + ')'
    //         }
    //         ctx.moveTo(dot.x, dot.y)
    //         ctx.lineTo(d2.x, d2.y)
    //         ctx.stroke()
    //       }
    //     }
    //     // 将已经计算过的粒子从数组中删除
    //     ndots.splice(ndots.indexOf(dot), 1)
    //   })
    //   this.animationFrame1 = window.requestAnimationFrame(this.drawDot)
    // },
    rads(x) {
      // 弧度转换
      return (Math.PI * x) / 180
    },
    act() {
      //清空画布
      const canvas = document.querySelector('#main')
      canvas.style.width = this.centerBox.height + 'px'
      canvas.style.height = this.centerBox.height + 'px'
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      this.drawPie(this.everyPer, context)
      this.animationFrame2 = window.requestAnimationFrame(this.act)
      this.everyPer += Math.PI / 180
      let speed = 0.07 //波浪速度，数越大速度越快
      this.xOffset += speed
    },
    drawPie(everyPer, context) {
      context.save()
      context.fillStyle = 'rgba(18,55,88,.2)'
      context.beginPath()
      context.arc(this.circle.x, this.circle.y, 245, 0, 2 * Math.PI, true)
      context.closePath()
      context.fill()
      context.restore()

      //外圆
      context.save()
      context.shadowBlur = 50
      context.shadowColor = '#123959'
      context.fillStyle = '#080D27'
      context.beginPath()
      context.arc(this.circle.x, this.circle.y, 235, 0, 2 * Math.PI, true)
      context.closePath()
      context.fill()
      context.restore()
      for (let i = 0; i < this.title.length; i++) {
        //绘制文字。
        context.save()
        // 画文字
        this.drawCircularText(this.circle, this.title[i], this.rads(i * 90 - 110), this.rads(i * 90 - 65), i, context)
        context.restore()
      }
      // 旋转小球
      let x = 240 * Math.cos(everyPer)
      let y = 240 * Math.sin(everyPer)
      context.save()
      context.fillStyle = 'rgb(56,252,253)'
      context.shadowBlur = 80
      context.shadowColor = '#39E9EE'
      context.translate(this.circle.x, this.circle.y)
      context.beginPath()
      context.arc(x, y, 5, 0, 2 * Math.PI)
      context.arc(-x, -y, 5, 0, 2 * Math.PI)
      context.closePath()
      context.fill()
      context.restore()
      //
      context.save()
      context.fillStyle = '#153776'
      context.beginPath()
      context.arc(this.circle.x, this.circle.y, 200, 0, 2 * Math.PI, true)
      context.closePath()
      context.fill()

      context.fillStyle = '#121535'
      context.beginPath()
      context.arc(this.circle.x, this.circle.y, 190, 0, 2 * Math.PI, true)
      context.closePath()
      context.fill()
      //内圆
      let nowRange = 36
      context.save()
      this.drawCircle(context)
      this.drawSin(this.xOffset, context, nowRange)
      // this.drawText(context, nowRange);
      context.restore()
      for (let i = 0; i < 6; i++) {
        //绘制刻度。
        context.save()
        context.translate(this.circle.x, this.circle.y)
        context.rotate(-Math.PI / 2 + Math.PI / 6 + (i * Math.PI) / 3) //旋转坐标轴。坐标轴x的正方形从 向上开始算起
        context.beginPath()
        context.moveTo(190, 0)
        context.lineTo(200, 0)
        context.lineWidth = 4
        context.strokeStyle = '#0A122D'
        context.stroke()
        context.closePath()
        context.restore()
      }
    },
    drawCircle(ctx) {
      // 画圆 中心圆
      ctx.beginPath()
      ctx.fillStyle = '#209ADF'
      ctx.arc(this.circle.x, this.circle.y, 120, 0, 2 * Math.PI)
      ctx.fill()
      ctx.beginPath()
      ctx.arc(this.circle.x, this.circle.y, 120, 0, 2 * Math.PI)
      ctx.clip()
    },
    drawSin(xOffset, ctx, nowRange) {
      //画sin 曲线函数
      let mW = 240
      let mH = 240
      let sX = 0
      let axisLength = mW //轴长
      let waveWidth = 0.04 //波浪宽度,数越小越宽
      let waveHeight = 12 //波浪高度,数越大越高
      ctx.save()
      ctx.translate(130, 130)
      let points = [] //用于存放绘制Sin曲线的点
      ctx.beginPath()
      //在整个轴长上取点
      for (let x = sX; x < sX + axisLength; x += 20 / axisLength) {
        //此处坐标(x,y)的取点，依靠公式 “振幅高*sin(x*振幅宽 + 振幅偏移量)”
        let y = -Math.sin((sX + x) * waveWidth + xOffset)
        let dY = mH * (1 - nowRange / 100)
        points.push([x, dY, dY + y * waveHeight])
        ctx.lineTo(x, dY + y * waveHeight)
      }
      //封闭路径
      ctx.lineTo(axisLength, mH)
      ctx.lineTo(sX, mH)
      ctx.lineTo(points[0][0], points[0][1])
      ctx.fillStyle = '#2C50B1'
      ctx.fill()

      ctx.restore()
    },
    // 中心显示文字
    drawText(ctx, nowRange) {
      ctx.save()
      ctx.translate(130, 130)
      let size = 50
      ctx.font = size + 'px Microsoft Yahei'
      ctx.textAlign = 'center'
      ctx.fillStyle = '#95EFFF'
      ctx.fillText(nowRange + '%', 120, 120 - size / 2)
      ctx.restore()
      ctx.save()
      size = 25
      ctx.translate(130, 130)
      ctx.font = size + 'px Microsoft Yahei'
      ctx.textAlign = 'center'
      ctx.fillStyle = '#95EFFF'
      ctx.fillText('平均供应指数', 120, 120 + size)
      ctx.restore()
    },
    // 旋转的文字
    drawCircularText(s, string, startAngle, endAngle, n, context) {
      let radius = s.radius, // 文字环绕的中心圆半径
        angleDecrement, // 一个文字所占的角度
        angle = parseFloat(startAngle), // 文字的起始角度
        index = 0, // 文字的索引值
        character // 当前要画的文字
      let arr = string.split(':')
      context.save()
      context.fillStyle = '#fff'
      context.font = '12px 微软雅黑 '
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      if (n < 2 || n === 5) {
        // 上三个不需要反转的文字
        while (index < string.length) {
          character = string.charAt(index)
          if (arr[0].indexOf(character) >= 0) {
            if (arr[0].length > 6) {
              angleDecrement = (startAngle - endAngle) / (string.length - 3)
            } else {
              angleDecrement = (startAngle - endAngle) / (string.length - 1)
            }
          } else {
            angleDecrement = (startAngle - endAngle) / (string.length + 6)
          }
          context.save()
          context.beginPath()
          context.translate(s.x + Math.cos(angle) * radius, s.y + Math.sin(angle) * radius)
          context.rotate(Math.PI / 2 + angle)
          context.fillText(character, 0, 0)
          angle -= angleDecrement
          index++
          context.restore()
        }
      } else {
        // 下面三个需要反转的文字
        while (index < string.length) {
          character = string.split('').reverse().join('').charAt(index) // 字符串反转
          if (arr[1].indexOf(character) >= 0) {
            angleDecrement = (startAngle - endAngle) / (string.length + 6)
          } else {
            if (arr[0].length > 6) {
              angleDecrement = (startAngle - endAngle) / (string.length - 3)
            } else {
              angleDecrement = (startAngle - endAngle) / (string.length - 1)
            }
          }
          context.save()
          context.beginPath()
          context.translate(s.x + Math.cos(angle) * radius, s.y + Math.sin(angle) * radius)
          context.rotate(-Math.PI / 2 + angle) // 旋转文字
          context.fillText(character, 0, 0)
          angle -= angleDecrement
          index++
          context.restore()
        }
      }
      context.restore()
    }
  },
  created() {
    //按重量统计品类进场
    this.getSPvarietyWeightTJ()
    //批发进出场走势图表
    this.getSPPFweightTJ()
    //农贸市场进场走势图 近半年内数据
    this.getSPretailInTJ()
    //农贸市场进出场重量列表
    this.getSPretailInWeiList()
    //点击获取商户列表
    this.getSPretailBusiInWeiList()
    //按区域  企业  环节 统计 进出场重量
    this.getSPInAndOutWeightTJ()
    this.getLinkData()
    this.getEnterpriseData()
  },
  // mounted() {
  //   this.centerBox = {
  //     width: document.querySelector('#circlePie').offsetWidth,
  //     height: document.querySelector('#circlePie').offsetHeight
  //   }
  //   for (let i = 0; i < 200; i++) {
  //     // 随机200个运动的圆点
  //     let x = Math.random() * this.centerBox.width // 随机的x偏移量
  //     let y = Math.random() * this.centerBox.height // 随机y轴偏移量
  //     let xa = Math.random() * 2 - 1 // x轴运动速度
  //     let ya = Math.random() * 2 - 1 // y轴运动速度
  //     this.dots.push({
  //       x: x,
  //       y: y,
  //       xa: xa,
  //       ya: ya,
  //       // 两个圆点之间需要连线的距离
  //       max: 40
  //     })
  //   }
  //   this.act()
  //   this.drawDot()
  //   this.resizeFn = this.$debounce(() => {
  //     // 通过捕获系统的onresize事件触发我们需要执行的事件
  //     this.centerBox = {
  //       width: document.querySelector('#circlePie').offsetWidth,
  //       height: document.querySelector('#circlePie').offsetHeight
  //     }
  //     for (let i = 1; i < 13; i++) {
  //       this.$refs['chart' + i].setChart()
  //     }
  //   }, 500)
  //   window.addEventListener('resize', this.resizeFn)
  // },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
    window.cancelAnimationFrame(this.animationFrame1)
    window.cancelAnimationFrame(this.animationFrame2)
  }
}
</script>

<style lang="less" scoped>
.page4 {
  height: 100%;
  width: 100%;
  padding: 14px 20px 20px;
  overflow: hidden;

  .content {
    height: 65%;

    .ivu-col {
      height: 100%;
    }

    .circlePie {
      height: 100%;
      padding: 0 0 40px;
      text-align: center;
      position: relative;

      canvas {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }

      #dot {
        background: rgba(0, 0, 0, 0);
      }
    }

    .list {
      height: 48%;

      .left,
      .right {
      }

      .left,
      .right,
      .center {
        width: 100%;
        height: 90%;
        border: 1px solid #0d2451;
        position: relative;

        #left1,
        #left2,
        #left3,
        #right1,
        #right2,
        #right3,
        #center2 {
          height: 100%;
        }

        .chart-68 {
          width: 68%;
          height: 100%;
          float: left;
        }

        .chart-32 {
          width: 32%;
          height: 100%;
          float: left;
        }
      }
    }
    .list2 {
      height: 96%;

      .left,
      .right {
      }

      .left,
      .right,
      .center {
        width: 100%;
        height: 96%;
        border: 1px solid #0d2451;
        position: relative;
        #right_1 {
          margin-top: 5%;
        }
        #left1,
        #left2,
        #left3,
        #right1,
        #right2,
        #right3,
        #center2 {
          height: 100%;
        }

        .chart-68 {
          width: 68%;
          height: 100%;
          float: left;
        }

        .chart-32 {
          width: 32%;
          height: 100%;
          float: left;
        }
      }
    }
  }

  .bottom-list {
    height: 35%;

    .ivu-col {
      height: 100%;

      .list {
        height: 100%;
        width: 100%;
        padding-right: 1.5%;
        float: left;

        #bottom_4 {
          padding: 0 20px;
        }

        .bottom {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }

      .right-bottom {
        width: 100%;
        padding-right: 0;

        .bottom1 {
          width: 100%;
        }
      }
    }
  }
}
</style>
